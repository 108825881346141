import { ISvgPreset } from '@/shared/types'

function ExternalLinkIcon({ width, height, fill }: ISvgPreset) {
  return (
    <svg
      width={width ? width : '1em'}
      height={height ? height : '1em'}
      viewBox="0 0 12 13"
      fill={fill ? fill : 'none'}>
      <path
        d="M4.666 2.5H1.999c-.736 0-1.333.597-1.333 1.334V10.5c0 .737.597 1.334 1.333 1.334h6.667c.736 0 1.333-.597 1.333-1.334V7.834M7.333 1.167h4m0 0v4m0-4L4.666 7.834"
        stroke="#fff"
        strokeOpacity={0.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ExternalLinkIcon
