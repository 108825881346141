import { ISvgPreset } from '@/shared/types'

function HcListed({ width, height, fill }: ISvgPreset) {
  return (
    <svg
      width={width ? width : '1em'}
      height={height ? height : '1em'}
      viewBox="0 0 20 20"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.083 5.167a1.917 1.917 0 100 3.833 1.917 1.917 0 000-3.833zM6.5 7.083a.583.583 0 111.167 0 .583.583 0 01-1.167 0z"
        fill={fill ? fill : '#fff'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 1.75a.75.75 0 00-.75.75V10c0 .199.079.39.22.53l8.333 8.334a.75.75 0 001.06 0l7.5-7.5a.75.75 0 000-1.061L10.53 1.97a.75.75 0 00-.53-.22H2.5zm.75 7.94V3.25h6.44l7.583 7.583-6.44 6.44L3.25 9.689z"
        fill={fill ? fill : '#fff'}
      />
    </svg>
  )
}

export default HcListed
