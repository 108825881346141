import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'

import { HcChevronLeft, HcChevronRight } from '@/shared/assets'
import { ISeatEventsInfo } from '@/shared/types'
import { cx } from '@/shared/utils'

interface ICalendarProps {
  availableDates: ISeatEventsInfo[]
  selectedDate: ISeatEventsInfo | null
  setSelectedDate: (date: ISeatEventsInfo) => void
}

interface ICalendarHeaderProps {
  currentMonth: dayjs.Dayjs
  setCurrentMonth: (currentMonth: dayjs.Dayjs) => void
  maxMonth: dayjs.Dayjs | undefined
}

interface ICalendarDaysProps {
  currentMonth: dayjs.Dayjs
  availableDates: ISeatEventsInfo[]
  selectedDate: ISeatEventsInfo | null
  setSelectedDate: (date: ISeatEventsInfo) => void
}

interface IIsSameOrAfterDatesProps {
  targetDate: Dayjs
  referenceDate: Dayjs
  unit: 'month' | 'day'
}

const isSameOrAfterDates = ({
  targetDate,
  referenceDate,
  unit = 'day',
}: IIsSameOrAfterDatesProps): boolean => {
  return targetDate.isSame(referenceDate, unit) || targetDate.isAfter(referenceDate, unit)
}

const Header = ({ currentMonth, setCurrentMonth, maxMonth }: ICalendarHeaderProps) => (
  <div className="flex items-center justify-center gap-1 my-4 h-9">
    <div
      onClick={() => {
        if (currentMonth.isSame(dayjs().startOf('month'), 'month')) return
        setCurrentMonth(currentMonth.subtract(1, 'month'))
      }}>
      <HcChevronLeft
        fill={currentMonth.isSame(dayjs().startOf('month'), 'month') ? '#484848' : '#fff'}
        width="20"
        height="20"
        opacity="0.64"
      />
    </div>
    <h3 className="text-lg font-semibold">{currentMonth.format('YYYY.MM')}</h3>
    <div
      onClick={() => {
        if (
          !maxMonth ||
          isSameOrAfterDates({ targetDate: currentMonth, referenceDate: maxMonth, unit: 'month' })
        )
          return
        setCurrentMonth(currentMonth.add(1, 'month'))
      }}>
      <HcChevronRight
        fill={
          !maxMonth ||
          isSameOrAfterDates({ targetDate: currentMonth, referenceDate: maxMonth, unit: 'month' })
            ? '#484848'
            : '#fff'
        }
        width="20"
        height="20"
        opacity="0.64"
      />
    </div>
  </div>
)

const Days = ({
  currentMonth,
  availableDates,
  selectedDate,
  setSelectedDate,
}: ICalendarDaysProps) => {
  const daysInMonth = (date: Dayjs): (Dayjs | null)[] => {
    const monthStart = date.startOf('month')
    const days = Array.from({ length: date.daysInMonth() }, (_, i) => monthStart.add(i, 'day'))

    const prependNulls = Array.from({ length: monthStart.day() }, () => null)
    const appendNulls = Array.from(
      { length: (7 - ((prependNulls.length + days.length) % 7)) % 7 },
      () => null,
    )

    return [...prependNulls, ...days, ...appendNulls]
  }
  const handleDayClick = (day: Dayjs) => {
    const isoDate = day.format('YYYY-MM-DD')
    const selected = availableDates.find(d => d.eventDate.startsWith(isoDate))
    if (selected && day.month() === currentMonth.month()) {
      setSelectedDate(selected)
    }
  }
  return (
    <div className="grid grid-cols-7 text-sm h-[30vh] font-normal">
      {daysInMonth(currentMonth).map((day, index) => {
        if (day === null) {
          return <div key={index} className="w-9 h-9"></div>
        }
        const isAvailable = availableDates.some(d =>
          d.eventDate.startsWith(day.format('YYYY-MM-DD')),
        )
        const isDisabledDate = !isAvailable || day.month() !== currentMonth.month()
        return (
          <button
            key={index}
            className={cx(
              'w-9 h-9 rounded-full flex justify-center items-center',
              selectedDate?.eventDate.startsWith(day.format('YYYY-MM-DD'))
                ? 'bg-appBgAccent !text-black'
                : '',
              isAvailable ? 'text-white' : 'text-appTextQuaternary',
            )}
            onClick={() => handleDayClick(day)}
            disabled={isDisabledDate}>
            {day.date()}
          </button>
        )
      })}
    </div>
  )
}

export const Calendar: React.FC<ICalendarProps> = ({
  availableDates,
  selectedDate,
  setSelectedDate,
}) => {
  const [currentMonth, setCurrentMonth] = useState(dayjs())
  const [maxMonth, setMaxMonth] = useState<Dayjs>()

  useEffect(() => {
    const dates = availableDates.map(date => dayjs(date.eventDate))
    if (dates.length > 0) {
      const maxDate = dayjs(Math.max(...dates.map(date => date.valueOf())))
      setMaxMonth(maxDate.startOf('month'))
    }
  }, [availableDates])

  return (
    <div>
      <Header currentMonth={currentMonth} setCurrentMonth={setCurrentMonth} maxMonth={maxMonth} />
      <Days
        currentMonth={currentMonth}
        availableDates={availableDates}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </div>
  )
}
