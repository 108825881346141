import { CheckIcon } from '@mantine/core'
import Image from 'next/image'
import { useCallback, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { shortenAddress } from '@/shared/utils/shortenAddress'

function CopyAddressButton({
  accountAddress,
  isOnlyButton,
}: {
  accountAddress: string
  isOnlyButton?: boolean
}) {
  const { t } = useTranslation(['common'])
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const handleClickCopy = useCallback(() => {
    toast(t('common.toast-copied-wallet-address'), {
      toastId: 'share',
    })

    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }, [t])

  if (isOnlyButton)
    return (
      <CopyToClipboard text={accountAddress} onCopy={handleClickCopy}>
        <button>
          {isCopied ? (
            <CheckIcon className="w-[18px] h-[18px] text-green-500" />
          ) : (
            <Image width={20} height={20} src="/img/icon/solid-duplicate.svg" alt="copy_icon" />
          )}
        </button>
      </CopyToClipboard>
    )

  return (
    <CopyToClipboard text={accountAddress} onCopy={handleClickCopy}>
      <button className="text-textSecondary text-sm w-[129px] h-[34px] rounded bg-bgQuarternary leading-normal inline-flex px-3 py-[6.5px] items-center gap-1">
        <span>{accountAddress && shortenAddress(accountAddress)}</span>
        <div className="ml-1">
          {isCopied ? (
            <CheckIcon className="w-[18px] h-[18px] text-green-500" />
          ) : (
            <Image
              width={16}
              height={16}
              className="text-textSecondary"
              src="/img/icon/copy.svg"
              alt="copy_icon"
            />
          )}
        </div>
      </button>
    </CopyToClipboard>
  )
}

export default CopyAddressButton
