import { ISvgPreset } from '@/shared/types'

interface ISvgPresetWithOpacity extends ISvgPreset {
  opacity?: string
}

function HcChevronLeft({ width, height, fill, opacity = '1' }: ISvgPresetWithOpacity) {
  return (
    <svg
      width={width ? width : '1em'}
      height={height ? height : '1em'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3485 6.84853C15.8172 6.3799 15.8172 5.6201 15.3485 5.15147C14.8799 4.68284 14.1201 4.68284 13.6515 5.15147L15.3485 6.84853ZM8.5 12L7.65147 11.1515C7.18284 11.6201 7.18284 12.3799 7.65147 12.8485L8.5 12ZM13.6515 18.8485C14.1201 19.3172 14.8799 19.3172 15.3485 18.8485C15.8172 18.3799 15.8172 17.6201 15.3485 17.1515L13.6515 18.8485ZM13.6515 5.15147L7.65147 11.1515L9.34853 12.8485L15.3485 6.84853L13.6515 5.15147ZM7.65147 12.8485L13.6515 18.8485L15.3485 17.1515L9.34853 11.1515L7.65147 12.8485Z"
        fill={fill}
        opacity={opacity}
      />
    </svg>
  )
}

export default HcChevronLeft
