import Image from 'next/image'

import { CHAIN_ICON_MAPPINGS } from '@/shared/constants'
import { useBreakpoint } from '@/shared/hooks'

export const ChainTag = ({ chainId }: { chainId: string }) => {
  const { isMD } = useBreakpoint()

  return (
    <div className="w-fit text-textPrimary md:px-3 px-2 md:py-2 py-1 md:h-10 h-[28px] bg-bgTertiary flex items-center justify-center rounded-full gap-1">
      <div className="flex items-center justify-center w-5 h-5 rounded-full md:w-6 md:h-6 bg-bgSecondary">
        <Image
          src={CHAIN_ICON_MAPPINGS[chainId].img}
          alt="polygon img"
          width={isMD ? 14 : 12}
          height={isMD ? 14 : 12}
        />
      </div>
      <div className="text-xs font-normal md:text-sm">{CHAIN_ICON_MAPPINGS[chainId].name}</div>
    </div>
  )
}
