import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/core-ui'
import { ROUTE_PATH } from '@/shared/constants/routePath'
import { useDialog } from '@/shared/hooks'

function NotEnabled() {
  const { t } = useTranslation('web-view-item')
  const { replace } = useRouter()
  const { setDialog } = useDialog()

  return (
    <main>
      <div className="w-full mt-[60px] lg:mt-[132px]">
        <div className="text-sm lg:text-lg font-Pretendard text-center">
          <span className="whitespace-pre-line">
            {t('web-view-item:view-item.go-home-contents')}
          </span>
        </div>
        <div className="w-full flex justify-center items-center">
          <div className="w-fit">
            <Button
              theme="Accent"
              text={t('web-view-item:view-item.go-home-cta')}
              onClick={() => {
                replace(ROUTE_PATH.HOME)
                setDialog(null)
              }}
              className="text-sm lg:text-base mt-8 px-8"
            />
          </div>
        </div>
      </div>
    </main>
  )
}

export default NotEnabled
