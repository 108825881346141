/* eslint-disable @next/next/no-img-element */
import { useTranslation } from 'react-i18next'

import { PaymentType } from '@/shared/types'
import optimizeImage from '@/shared/utils/optimizeImage'

interface ITradeItemInfoProps {
  mediaUrl: string
  collectionTitle: string
  itemTitle: string
  floorPrice: number | null
  paymentType: PaymentType
  children?: React.ReactNode
}

const TradeItemInfo = ({
  mediaUrl,
  collectionTitle,
  itemTitle,
  floorPrice,
  paymentType,
  children,
}: ITradeItemInfoProps) => {
  const { t } = useTranslation('common')

  return (
    <div className="flex items-center justify-between mt-4 md:w-80 md:max-w-xs">
      <div className="flex items-center gap-2">
        <img
          src={optimizeImage(mediaUrl, 48, 48)}
          alt={`${collectionTitle} - ${itemTitle}`}
          className="rounded-sm object-contain aspect-square w-[40px] h-[40px]"
          onError={e => {
            e.currentTarget.src = '/img/placeholder/item-card-replace.png'
          }}
        />
        <div className="max-w-[200px]">
          <p className="text-textSecondary truncate text-sm">{collectionTitle ?? '-'}</p>
          <p className="text-white font-medium truncate">{itemTitle ?? '-'}</p>
        </div>
      </div>
      {children}
      {!children && floorPrice !== null && (
        <div className="min-w-fit">
          <p className="text-xs text-textTertiary text-end">
            {t('common:common.collection-floor-price')}
          </p>
          <p className="text-xs text-textSecondary font-medium mt-1 text-end">
            {floorPrice} {paymentType}
          </p>
        </div>
      )}
    </div>
  )
}

export default TradeItemInfo
