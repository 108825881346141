interface IBarIndicatorPropsType {
  activeIndex: number
  totalSlideDataCount: number
  totalIndicatorWidth?: number
}
const BarIndicator = ({
  activeIndex,
  totalSlideDataCount,
  totalIndicatorWidth = 120,
}: IBarIndicatorPropsType) => {
  const perIndicatorWidth = Math.floor(totalIndicatorWidth / totalSlideDataCount)
  const perIndicatorMarginLeft = perIndicatorWidth * activeIndex
  return (
    <div className="flex items-center justify-center w-full mt-6">
      <div className="w-[120px] h-[2px] relative bg-bgQuarternary">
        <div
          style={{
            width: `${perIndicatorWidth}px`,
            marginLeft: `${perIndicatorMarginLeft}px`,
            height: '2px',
            position: 'absolute',
            zIndex: '10',
            backgroundColor: 'white',
          }}
        />
      </div>
    </div>
  )
}

export default BarIndicator
