interface ISpace {
  top?: number
  bottom?: number
  right?: number
  left?: number
}

export const Space = ({ top = 0, bottom = 0, right = 0, left = 0 }: ISpace) => {
  return <div className={`mt-[${top}px] mb-[${bottom}px] mr-[${right}px] ml-[${left}px]`} />
}
