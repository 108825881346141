/* eslint-disable @next/next/no-img-element */

import { useEffect, useRef, useState } from 'react'

import { getFileExtension } from '@/shared/utils'

interface IVideoProps {
  mediaUrl: string
  fallbackImage?: string
  handleGoToItemDetail?: () => void
}

export const Video = ({
  mediaUrl,
  handleGoToItemDetail,
  fallbackImage = '/img/placeholder/item-card-replace.png',
}: IVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isOnVideoError, setIsOnVideoError] = useState<boolean>(false)

  useEffect(() => {
    setIsOnVideoError(false)

    if (videoRef.current) {
      videoRef.current.load()
    }
  }, [mediaUrl])

  return isOnVideoError ? (
    <img
      alt="item card image"
      src={fallbackImage}
      onClick={handleGoToItemDetail}
      className="object-cover w-full h-full"
    />
  ) : (
    <video
      loop
      muted
      controls
      autoPlay
      playsInline
      ref={videoRef}
      onClick={handleGoToItemDetail}
      onError={() => setIsOnVideoError(true)}>
      <source src={mediaUrl} type={`video/${getFileExtension(mediaUrl)}`} />
    </video>
  )
}
