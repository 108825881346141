import { ISvgPreset } from '@/shared/types'

function HcTip({ width = '1em', height = '1em', fill = 'white', theme = 'white' }: ISvgPreset) {
  if (theme === 'white') {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.4999 2.16553C5.89695 2.16553 2.16553 5.89695 2.16553 10.4999C2.16553 15.1029 5.89695 18.8343 10.4999 18.8343C15.1029 18.8343 18.8343 15.1029 18.8343 10.4999C18.8343 5.89695 15.1029 2.16553 10.4999 2.16553ZM11.8119 7.21865C11.8119 7.94352 11.2243 8.53115 10.4994 8.53115C9.77452 8.53115 9.18689 7.94352 9.18689 7.21865C9.18689 6.49378 9.77452 5.90615 10.4994 5.90615C11.2243 5.90615 11.8119 6.49378 11.8119 7.21865ZM11.4137 10.4114C11.3692 9.94552 10.9767 9.58116 10.4991 9.58116C9.99172 9.58116 9.58038 9.99249 9.58038 10.4999V14.4374L9.58459 14.5259C9.62911 14.9918 10.0216 15.3562 10.4991 15.3562C11.0065 15.3562 11.4179 14.9448 11.4179 14.4374V10.4999L11.4137 10.4114Z"
          fill={fill}
          fillOpacity="0.64"
        />
      </svg>
    )
  } else {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.2495 6.87501C11.2495 7.56536 10.6899 8.12501 9.99951 8.12501C9.30916 8.12501 8.74951 7.56536 8.74951 6.87501C8.74951 6.18465 9.30916 5.62501 9.99951 5.62501C10.6899 5.62501 11.2495 6.18465 11.2495 6.87501Z"
          fill="white"
          fillOpacity="0.64"
        />
        <path
          d="M10.8703 9.91574C10.8279 9.47203 10.4541 9.12501 9.99927 9.12501C9.51602 9.12501 9.12427 9.51676 9.12427 10V13.75L9.12827 13.8343C9.17067 14.278 9.54444 14.625 9.99927 14.625C10.4825 14.625 10.8743 14.2333 10.8743 13.75V10L10.8703 9.91574Z"
          fill="white"
          fillOpacity="0.64"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.0625 10C2.0625 5.61624 5.61624 2.0625 10 2.0625C14.3838 2.0625 17.9375 5.61624 17.9375 10C17.9375 14.3838 14.3838 17.9375 10 17.9375C5.61624 17.9375 2.0625 14.3838 2.0625 10ZM16.4377 10C16.4377 6.44466 13.5556 3.5625 10.0002 3.5625C6.44491 3.5625 3.56274 6.44466 3.56274 10C3.56274 13.5553 6.44491 16.4375 10.0002 16.4375C13.5556 16.4375 16.4377 13.5553 16.4377 10Z"
          fill="white"
          fillOpacity="0.64"
        />
      </svg>
    )
  }
}

export default HcTip
