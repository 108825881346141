import { ISvgPreset } from '@/shared/types'

function EditIcon({ width, height, fill }: ISvgPreset) {
  return (
    <svg
      width={width ? width : '1em'}
      height={height ? height : '1em'}
      viewBox="0 0 14 14"
      fill="none">
      <path
        d="M10.586.586a2 2 0 112.828 2.828l-.793.793L9.793 1.38l.793-.793zM8.379 2.793L0 11.172V14h2.828l8.38-8.379-2.83-2.828z"
        fill={fill ? fill : '#fff'}
        fillOpacity={0.64}
      />
    </svg>
  )
}

export default EditIcon
