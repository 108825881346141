import HcChevronLeft from '@/shared/assets/HcChevronLeft'
import HcChevronRight from '@/shared/assets/HcChevronRight'
import { cx } from '@/shared/utils'

interface ICarouselArrowButtonProps {
  onClick: () => void
  position: 'left' | 'right'
  color?: 'white' | 'tertiary'
  isHomeEvent?: boolean
}

export default function CarouselArrowButton({
  onClick,
  position = 'right',
  color = 'tertiary',
  isHomeEvent = false,
}: ICarouselArrowButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cx(
        'w-[36px] h-[36px] rounded-2xl flex justify-center items-center cursor-pointer absolute translate-y-[-50%] z-10',
        color === 'white' ? 'bg-white' : 'bg-bgTertiary',
        position === 'left' ? 'left-[-0.5%]' : 'right-[-10px]',
        isHomeEvent ? 'top-[41%]' : 'top-[50%]',
      )}>
      {position === 'left' ? (
        <HcChevronLeft
          fill={color === 'white' ? '#111' : '#fff'}
          width="20"
          height="20"
          opacity="0.64"
        />
      ) : (
        <HcChevronRight
          fill={color === 'white' ? '#111' : '#fff'}
          width="20"
          height="20"
          opacity="0.64"
        />
      )}
    </button>
  )
}
