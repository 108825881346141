import { ISvgPreset } from '@/shared/types'

function HcOffer({ width, height, fill }: ISvgPreset) {
  return (
    <svg
      width={width ? width : '1em'}
      height={height ? height : '1em'}
      viewBox="0 0 20 20"
      fill="none">
      <path
        d="M12.115 4.54a.583.583 0 00-.896-.746L9.543 5.803l-.798-.798a.583.583 0 00-.825.824l1.25 1.25a.583.583 0 00.86-.039l2.084-2.5z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 .167a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zm-3.917 5.25a3.917 3.917 0 117.834 0 3.917 3.917 0 01-7.834 0zM15.779 11.612c.26-.26.682-.26.942 0l2.084 2.083c.26.26.26.683 0 .943l-5 5a.667.667 0 01-.472.196h-7.5a.667.667 0 010-1.334h7.224l4.333-4.333-1.14-1.14-3.279 3.278a.667.667 0 01-1.138-.471V14H6.11l-3.97 3.972a.667.667 0 01-.944-.943l4.167-4.167a.667.667 0 01.471-.195H12.5c.368 0 .667.298.667.667v.89l2.612-2.612z"
        fill={fill ? fill : '#fff'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 15.834c0-.369.298-.667.667-.667H12.5a.667.667 0 110 1.333H7.917a.667.667 0 01-.667-.666z"
        fill={fill ? fill : '#fff'}
      />
    </svg>
  )
}

export default HcOffer
