import { useTranslation } from 'react-i18next'

export const UnSupportedNFTNotice = () => {
  const { t } = useTranslation('common')

  return (
    <div className="flex flex-col gap-1">
      <p className="text-xs font-medium text-appTextPrimary leading-[15px]">
        {t('common.label-trade-non-supported-nft')}
      </p>
      <p className="text-xs font-medium text-appTextTertiary leading-4 whitespace-pre-line">
        {t('common:common.label-konkrit-unsupported-nft-description')}
      </p>
    </div>
  )
}
