/* eslint-disable @next/next/no-img-element */
import { useTranslation } from 'react-i18next'

import { ManagedDialog } from '@/shared/constants'
import { useDialog } from '@/shared/hooks'

export const TradeSupportItemDialogButton = () => {
  const { setDialog } = useDialog()
  const { t } = useTranslation('common')
  const handleTradeSupportItemDialogOpen = () => {
    setDialog(ManagedDialog.tradeSupportItem)
  }
  return (
    <div className="w-full flex items-center justify-start mb-4">
      <button type="button" onClick={handleTradeSupportItemDialogOpen}>
        <div className="rounded-sm py-2 px-3 flex gap-1 items-center justify-center bg-appBgQuaternary">
          <img src="/img/icon/hcicon-outline-info.svg" alt="info" />
          <p className="text-xs text-appTextPrimary leading-[15px]">
            {t('common.account-guide-title')}
          </p>
        </div>
      </button>
    </div>
  )
}
