const RefreshButton = ({
  theme = 'base',
  classNames = '',
  onClick,
  size,
}: {
  theme?: 'base' | 'white'
  classNames?: string
  onClick?: () => void
  size?: string
}) => {
  if (size === 'sm') {
    return (
      <button type="button" className={classNames} onClick={onClick}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3675 9.6875C16.2045 6.31184 13.416 3.625 10 3.625C6.47918 3.625 3.625 6.47918 3.625 10C3.625 13.5208 6.47918 16.375 10 16.375C11.1415 16.375 12.2409 16.0739 13.2065 15.5109C13.3005 15.4562 13.3931 15.399 13.4841 15.3395L14.1119 14.9291L13.2911 13.6735L12.6634 14.0839L12.5581 14.1508L12.4511 14.2151C11.7132 14.6452 10.8743 14.875 10 14.875C7.30761 14.875 5.125 12.6924 5.125 10C5.125 7.30761 7.30761 5.125 10 5.125C12.5874 5.125 14.704 7.14073 14.8651 9.6875H13.6144C12.9309 9.6875 12.5622 10.4892 13.007 11.0081L15.0176 13.3539C15.3369 13.7263 15.9131 13.7264 16.2324 13.3539L18.243 11.0081C18.6878 10.4892 18.3191 9.6875 17.6356 9.6875H16.3675Z"
            fill="#E8E8E8"
            fillOpacity={theme === 'base' ? '0.64' : '1'}
          />
        </svg>
      </button>
    )
  }

  return (
    <button type="button" className={classNames} onClick={onClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6411 11.6251C19.4455 7.5743 16.0993 4.3501 12.0001 4.3501C7.77512 4.3501 4.3501 7.77512 4.3501 12.0001C4.3501 16.2251 7.77512 19.6501 12.0001 19.6501C13.3699 19.6501 14.6891 19.2888 15.8479 18.6132C15.9607 18.5475 16.0718 18.4789 16.181 18.4074L16.9344 17.915L15.9495 16.4084L15.1961 16.9008L15.0698 16.9811L14.9414 17.0582C14.056 17.5744 13.0492 17.8501 12.0001 17.8501C8.76923 17.8501 6.1501 15.231 6.1501 12.0001C6.1501 8.76923 8.76923 6.1501 12.0001 6.1501C15.105 6.1501 17.6449 8.56898 17.8383 11.6251H15.9895C15.306 11.6251 14.9373 12.4268 15.3821 12.9457L18.1427 16.1665C18.462 16.5389 19.0382 16.5389 19.3575 16.1665L22.1181 12.9457C22.5629 12.4268 22.1942 11.6251 21.5107 11.6251H19.6411Z"
          fill="#E8E8E8"
          fillOpacity={theme === 'base' ? '0.64' : '1'}
        />
      </svg>
    </button>
  )
}
export default RefreshButton
