import { ISvgPreset } from '@/shared/types'

function HcSold({ width, height, fill }: ISvgPreset) {
  return (
    <svg
      width={width ? width : '1em'}
      height={height ? height : '1em'}
      viewBox="0 0 20 20"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.09 1.187a.75.75 0 011.056-.096l2.5 2.083a.75.75 0 01.27.577l-.002 1.75h11.752a.75.75 0 01.75.75v7.083a.75.75 0 01-.75.75h-10a.75.75 0 01-.53-.22l-2.508-2.5a.75.75 0 01-.22-.532l.005-4.552a.764.764 0 010-.061L3.416 4.1l-2.23-1.858a.75.75 0 01-.096-1.056zM4.912 7l-.004 3.522 2.068 2.062h8.94V7H4.912zM3.166 17.084a1.833 1.833 0 113.667 0 1.833 1.833 0 01-3.667 0zm1.833-.667a.667.667 0 100 1.333.667.667 0 000-1.333zM15.833 15.25a1.833 1.833 0 100 3.667 1.833 1.833 0 000-3.667zm-.667 1.834a.667.667 0 111.333 0 .667.667 0 01-1.333 0z"
        fill={fill ? fill : '#fff'}
      />
    </svg>
  )
}

export default HcSold
