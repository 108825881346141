/* eslint-disable @next/next/no-img-element */
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import Link from 'next/link'
import { i18n } from 'next-i18next'
import { useTranslation } from 'react-i18next'

import { ROUTE_PATH } from '@/shared/constants'
import { useTime } from '@/shared/hooks'
import { ICollectionActivityItem, ICollectionActivityOrder, TradeType } from '@/shared/types'
import { cx, getChainscanUrl, shortenAddress, timeFormatter } from '@/shared/utils'

import { PriceLabel } from '.'

interface IActivityItemProps {
  type: TradeType
  createdAt: string
  item: ICollectionActivityItem
  transactionHash: string | null
  order: ICollectionActivityOrder
  toAccountAddress: string | null
  fromAccountAddress: string | null
}

const ActivityItem = ({
  type,
  item,
  order,
  createdAt,
  transactionHash,
  toAccountAddress,
  fromAccountAddress,
}: IActivityItemProps) => {
  return (
    <ActivityItem.Design
      type={type}
      item={item}
      order={order}
      createdAt={createdAt}
      transactionHash={transactionHash}
      toAccountAddress={toAccountAddress}
      fromAccountAddress={fromAccountAddress}
    />
  )
}

function ActivityListItemDesign({
  type,
  item,
  order,
  createdAt,
  transactionHash,
  toAccountAddress,
  fromAccountAddress,
}: IActivityItemProps) {
  const { t } = useTranslation('common')
  const language = i18n?.language || 'ko'

  const activityCommentMappings: {
    [type: string]: { collectionComment: JSX.Element }
  } = {
    LISTING: {
      collectionComment: (
        <>
          {language === 'ko' ? (
            <>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', fromAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(fromAccountAddress ?? '')}</span>
              </Link>
              <span>{t('common:common.label-register-for-sale-from').replace('{value}', '')}</span>
            </>
          ) : (
            <>
              <span>{t('common:common.label-register-for-sale-from').replace('{value}', '')}</span>
              &nbsp;
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', fromAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(fromAccountAddress ?? '')}</span>
              </Link>
            </>
          )}
        </>
      ),
    },
    LISTING_CANCEL: {
      collectionComment: (
        <>
          {language === 'ko' ? (
            <>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', fromAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(fromAccountAddress ?? '')}</span>
              </Link>
              <p>{t('common:common.label-cancel-sale-from').replace('{value}', '')}</p>
            </>
          ) : (
            <>
              <span>{t('common:common.label-cancel-sale-from').replace('{value}', '')}</span>
              &nbsp;
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', fromAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(fromAccountAddress ?? '')}</span>
              </Link>
            </>
          )}
        </>
      ),
    },
    LISTING_SALE: {
      collectionComment: (
        <>
          {language === 'ko' ? (
            <>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', fromAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(fromAccountAddress ?? '')}</span>
              </Link>
              <span>
                {t('common:common.transaction-history-description-user-1').replace('{value}', '')}
                &nbsp;
              </span>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', toAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(toAccountAddress ?? '')}</span>
              </Link>
              <span>
                {t('common:common.transaction-history-description-sale').replace('{value}', '')}
              </span>
            </>
          ) : (
            <>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', fromAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(fromAccountAddress ?? '')}</span>
              </Link>
              <span>
                {t('common:common.transaction-history-description-user-1').replace('{value}', '')}
              </span>
              &nbsp;
              <span>
                {t('common:common.transaction-history-description-sale').replace('{value}', '')}
              </span>
              &nbsp;
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', toAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(toAccountAddress ?? '')}</span>
              </Link>
            </>
          )}
        </>
      ),
    },
    TRANSFER: {
      collectionComment: (
        <>
          {language === 'ko' ? (
            <>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', fromAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(fromAccountAddress ?? '')}</span>
              </Link>
              <span>
                {t('common:common.transaction-history-description-user-1').replace('{value}', '')}
                &nbsp;
              </span>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', toAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(toAccountAddress ?? '')}</span>
              </Link>
              <span>
                {t('common:common.transaction-history-description-transfer').replace('{value}', '')}
              </span>
            </>
          ) : (
            <>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', fromAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(fromAccountAddress ?? '')}</span>
              </Link>
              &nbsp;
              <span>
                {t('common:common.transaction-history-description-transfer').replace('{value}', '')}
              </span>
              &nbsp;
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', toAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(toAccountAddress ?? '')}</span>
              </Link>
            </>
          )}
        </>
      ),
    },
    OFFER: {
      collectionComment: (
        <>
          {language === 'ko' ? (
            <>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', fromAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(fromAccountAddress ?? '')}</span>
              </Link>
              <span>
                {t('common:common.transaction-history-description-user-1').replace('{value}', '')}
                &nbsp;
              </span>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', toAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(toAccountAddress ?? '')}</span>
              </Link>
              <span>{t('common:common.label-offer-to').replace('{value}', '')}</span>
            </>
          ) : (
            <>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', fromAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(fromAccountAddress ?? '')}</span>
              </Link>
              &nbsp;
              {t('common:common.transaction-history-description-offer').replace('{value}', '')}
              &nbsp;
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', toAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(toAccountAddress ?? '')}</span>
              </Link>
            </>
          )}
        </>
      ),
    },
    OFFER_CANCEL: {
      collectionComment: (
        <>
          {language === 'ko' ? (
            <>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', fromAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(fromAccountAddress ?? '')}</span>
              </Link>
              <span>
                {t('common:common.transaction-history-description-user-1').replace('{value}', '')}
                &nbsp;
              </span>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', toAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(toAccountAddress ?? '')}</span>
              </Link>
              <span>
                {t('common:common.transaction-history-description-cancel-offer').replace(
                  '{value}',
                  '',
                )}
              </span>
            </>
          ) : (
            <>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', fromAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(fromAccountAddress ?? '')}</span>
              </Link>
              &nbsp;
              <span>
                {t('common:common.transaction-history-description-cancel-offer').replace(
                  '{value}',
                  '',
                )}
              </span>
              &nbsp;
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', toAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(toAccountAddress ?? '')}</span>
              </Link>
            </>
          )}
        </>
      ),
    },
    OFFER_SALE: {
      collectionComment: (
        <>
          {language === 'ko' ? (
            <>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', toAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(toAccountAddress ?? '')}</span>
              </Link>
              <span>
                {t('common:common.transaction-history-description-user-1').replace('{value}', '')}
                &nbsp;
              </span>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', fromAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(fromAccountAddress ?? '')}</span>
              </Link>
              <span>{t('common:common.label-buy-from').replace('{value}', '')}</span>
            </>
          ) : (
            <>
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', toAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(toAccountAddress ?? '')}</span>
              </Link>
              &nbsp;
              <span>{t('common:common.label-buy-from').replace('{value}', '')}</span>
              &nbsp;
              <Link href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', fromAccountAddress ?? '')}>
                <span className="text-textAccent">{shortenAddress(fromAccountAddress ?? '')}</span>
              </Link>
            </>
          )}
        </>
      ),
    },
  }

  const { collectionComment } = activityCommentMappings[type]
  const { getExpireTime } = useTime({ tradeType: type, size: 'sm', t })

  return (
    <div className="w-full flex justify-between border-b border-borderQuarternary py-4">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <p className="text-white text-sm font-semibold">{item.title}</p>
          <div className="flex flex-col text-white text-sm leading-normal">
            <div className="flex flex-wrap items-center">{collectionComment}</div>
            {(type === 'OFFER' || type === 'LISTING') && (
              <>
                <p
                  className={cx(
                    getExpireTime(order.expiredAt, t).isExpired
                      ? 'text-appTextError'
                      : 'text-appTextTertiary',
                  )}>
                  {getExpireTime(order.expiredAt, t).message}
                </p>
              </>
            )}
          </div>
        </div>

        <div className="flex items-center">
          <p className="text-textTertiary text-sm leading-normal">
            {timeFormatter(createdAt, 'YYYY.MM.DD A h:mm')}
          </p>

          <div
            className="flex items-center ml-1"
            onClick={() => {
              if (window !== undefined && transactionHash !== null)
                window.location.href = `${getChainscanUrl(item.chainId)}/tx/${transactionHash}`
            }}>
            <ArrowTopRightOnSquareIcon className="w-4 h-4 text-textTertiary" />
          </div>
        </div>
      </div>

      <PriceLabel size={'sm'} paymentType={order?.paymentType} price={Number(order?.price)} />
    </div>
  )
}

const ActivityListItemSkeleton = () => {
  return (
    <div className="animate-pulse w-full mb-4">
      <div className="w-full flex justify-between items-center border-b border-b-gray-500 p-4">
        <div>
          <div className="w-[210px] h-[21px] rounded-sm bg-bgQuarternary" />
          <div className="w-[90px] h-[21px] rounded-sm bg-bgQuarternary mt-1" />
          <div className="w-[150px] h-[21px] rounded-sm bg-bgQuarternary mt-2" />
        </div>
        <div className="w-12 h-[18px] bg-bgQuarternary" />
      </div>
    </div>
  )
}

export default ActivityItem

ActivityItem.Design = ActivityListItemDesign
ActivityItem.Skeleton = ActivityListItemSkeleton
