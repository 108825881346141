export interface ICollectionGridProps {
  children: React.ReactNode
}

export default function CollectionGrid({ children }: ICollectionGridProps) {
  return (
    <div className="grid w-full grid-cols-defaultCollection lg:grid-cols-defaultCollectionLG gap-[16px] lg:gap-[24px] pb-[24px]">
      {children}
    </div>
  )
}
