import { ISvgPreset } from '@/shared/types'

function HcSend({ width, height, fill, transform }: ISvgPreset) {
  return (
    <svg
      width={width ? width : '1em'}
      height={height ? height : '1em'}
      viewBox="0 0 20 20"
      transform={transform ? transform : ''}
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.209 2.743a.75.75 0 00-.952-.953L1.423 7.207a.75.75 0 00-.029 1.409l7.193 2.797 2.797 7.192a.75.75 0 001.408-.029L18.21 2.743zM8.98 9.956L3.851 7.962l12.444-4.257-4.257 12.443-1.996-5.132 2.571-2.572a.75.75 0 00-1.06-1.06L8.98 9.955z"
        fill={fill ? fill : '#fff'}
      />
    </svg>
  )
}

export default HcSend
