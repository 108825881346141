import { Space } from '@mantine/core'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { cx } from '@/shared/utils'

interface ICommonErrorFallbackProp {
  title: string
  bgColor?: string
  error?: any
  handleRetry: () => void
}

const CommonErrorFallback = ({
  title,
  bgColor = 'bg-bgSecondary',
  handleRetry,
}: ICommonErrorFallbackProp) => {
  const onlyOnce = useRef(false)
  const { t } = useTranslation('common')

  useEffect(() => {
    if (onlyOnce.current) return
    onlyOnce.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={cx(
        'w-full py-[40px] flex flex-col items-center justify-center rounded-md',
        bgColor,
      )}>
      <p className="w-full leading-[21px] text-base text-center text-appTextTertiary whitespace-pre-line">
        {title}
      </p>
      <Space h={16} />
      <button
        onClick={handleRetry}
        className="py-[13px] px-[20px] rounded-sm text-appTextPrimary bg-appBgQuaternary">
        {t('common.cta-label-try-again')}
      </button>
    </div>
  )
}

export default CommonErrorFallback
