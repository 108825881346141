import Image from 'next/image'

export const ClaimButton = ({ theme = 'base' }: { theme?: 'base' | 'accent' }) => {
  if (theme === 'base') {
    return (
      <div className="flex items-center justify-center gap-2 font-medium bg-bgQuarternary rounded-md h-[52px]">
        <Image src="/img/icon/gift.svg" width={16} height={16} alt="claim button" />
        <div>받을 수 있는 혜택 확인하기</div>
      </div>
    )
  }
  return (
    <div className="flex justify-between items-center w-full bg-bgAccent text-textBlack rounded-lg h-[77px] p-5 max-w-[328px]">
      <div className="flex flex-col gap-1">
        <div className="text-sm font-medium">클릭해서 혜택 확인하기</div>
        <div className="text-xs font-normal">받을 수 있는 혜택을 확인해보세요.</div>
      </div>
      <Image src="/img/icon/claim-gift.svg" width={56} height={56} alt="claim button" />
    </div>
  )
}
