export interface IDropdownButtonPropsType {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  theme?: 'base' | 'dark' | 'secondary'
  size?: 'base' | 'sm'
}

function DropdownButton({
  isOpen,
  setIsOpen,
  theme = 'base',
  size = 'base',
}: IDropdownButtonPropsType) {
  return (
    <svg
      data-accordion-icon
      className={isOpen ? 'rotate-180' : ''}
      onClick={() => setIsOpen(!isOpen)}
      width={size === 'base' ? '32' : '20'}
      height={size === 'base' ? '32' : '20'}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="32"
        y="32"
        width="32"
        height="32"
        rx="16"
        transform="rotate(-180 32 32)"
        fill={theme === 'base' ? '#3F3F46' : theme === 'secondary' ? '#2C2C2C' : '#0000'}
      />
      <path
        d="M16 14.5908L19.7045 18.2953C20.1438 18.7347 20.8562 18.7347 21.2955 18.2953C21.7348 17.856 21.7348 17.1437 21.2955 16.7043L16.7955 12.2043C16.3562 11.765 15.6438 11.765 15.2045 12.2043L10.7045 16.7043C10.2652 17.1437 10.2652 17.856 10.7045 18.2953C11.1438 18.7347 11.8562 18.7347 12.2955 18.2953L16 14.5908Z"
        fill={theme === 'base' || theme === 'secondary' ? 'white' : '#FFFFFF66'}
      />
    </svg>
  )
}

export default DropdownButton
