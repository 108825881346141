import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/core-ui'
import { APP_PATH_TYPE } from '@/shared/constants'
import { useIsReactNativeWebView } from '@/shared/hooks'
import { goToAppScreen } from '@/shared/utils'

function NotFound() {
  const { t } = useTranslation('web-view-item')
  const isWebView = useIsReactNativeWebView()

  const handleGoToHomeButton = useCallback(() => {
    if (isWebView === null || !isWebView) return

    goToAppScreen(APP_PATH_TYPE.HOME)
  }, [isWebView])

  return (
    <div className="w-full mt-[60px] lg:mt-[132px]">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-5 items-center">
          <div className="flex flex-col gap-1 font-normal leading-normal text-sm lg:text-lg font-Pretendard text-center">
            <span>{t('web-view-item:view-item.go-home-contents')}</span>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Button
            theme="Accent"
            text={t('web-view-item:view-item.go-home-cta')}
            onClick={handleGoToHomeButton}
            className="w-[142px] lg:w-[170px] h-[41px] lg:h-[52px] text-sm lg:text-base"
          />
        </div>
      </div>
    </div>
  )
}

export default NotFound
