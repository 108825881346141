import Image from 'next/image'
import { useCallback, useMemo } from 'react'

import { ACTIVITY_TYPE_NAME } from '@/shared/constants'
import { useBreakpoint } from '@/shared/hooks'
import { ActivityFilterType, FilterChip, IChipCollectionType } from '@/shared/types'
import { cx } from '@/shared/utils'

export interface IFilteredChipProps {
  close?: (chip: FilterChip) => void
  removeCollectionFilter?: (collectionAddress: string) => void
  removePropertyFilter?: (propertyId: number) => void
  removeActivityFilter?: (filterType: ActivityFilterType) => void
  clear: () => void
  chips: FilterChip[]
  collections?: IChipCollectionType[]
  propertyIds?: Array<{ id: number; chipName: string }>
  activities?: ActivityFilterType[]
  className?: string
}

interface IFilteredChipDesignProps extends IFilteredChipProps {
  getLabelText: (chip: FilterChip) => string
}

function FilteredChip({
  close,
  clear,
  chips,
  collections,
  propertyIds,
  activities,
  removeCollectionFilter,
  removePropertyFilter,
  removeActivityFilter,
  className = '',
}: IFilteredChipProps) {
  const getLabelText = useCallback(
    ({
      offeredChip,
      listingChip,
      minPriceChip,
      maxPriceChip,
      collectionChip,
      activityChip,
    }: FilterChip) => {
      if (listingChip) return '판매 중'
      else if (minPriceChip)
        return `> ${Number(minPriceChip.price)} ${minPriceChip.paymentType.toUpperCase()}`
      else if (maxPriceChip)
        return `< ${Number(maxPriceChip.price)} ${maxPriceChip.paymentType.toUpperCase()}`
      else if (offeredChip) return '제안 받은'
      else if (collectionChip) return collectionChip.name
      else if (activityChip) return activityChip.name
      else return 'input Chip'
    },
    [],
  )

  const getSortedChips = useCallback(
    (chips: FilterChip[]) =>
      chips.slice().sort((a, b) => {
        if (a.offeredChip === undefined && b.offeredChip) return 1
        if (a.offeredChip && b.offeredChip === undefined) return -1
        if (a.listingChip === undefined && b.listingChip) return 1
        if (a.listingChip && b.listingChip === undefined) return -1
        if (a.minPriceChip === undefined && b.minPriceChip) return 1
        if (a.minPriceChip && b.minPriceChip === undefined) return -1
        return 0
      }),
    [],
  )

  const sortedChips = useMemo(() => getSortedChips(chips), [chips, getSortedChips])

  return (
    <FilteredChipDesign
      chips={sortedChips}
      close={close}
      removeCollectionFilter={removeCollectionFilter}
      removePropertyFilter={removePropertyFilter}
      removeActivityFilter={removeActivityFilter}
      clear={clear}
      getLabelText={getLabelText}
      collections={collections}
      className={className}
      propertyIds={propertyIds}
      activities={activities}
    />
  )
}

function FilteredChipDesign({
  getLabelText,
  chips,
  removeCollectionFilter,
  removePropertyFilter,
  removeActivityFilter,
  collections,
  clear,
  close,
  className,
  propertyIds,
  activities,
}: IFilteredChipDesignProps) {
  const { isLG } = useBreakpoint()

  const defaultWrapperStyle = cx(
    'whitespace-nowrap overflow-x-auto scrollbar-hide mb-6 text-white flex gap-1',
    className,
  )
  const isNotEmptyChips =
    chips.length > 0 ||
    (collections !== undefined && collections.length > 0) ||
    (propertyIds !== undefined && propertyIds.length > 0) ||
    (activities !== undefined && activities.length > 0)

  if (!isNotEmptyChips) return <></>

  return (
    <ul className={isLG || isNotEmptyChips ? cx(defaultWrapperStyle) : defaultWrapperStyle}>
      {isNotEmptyChips && (
        <li
          className="w-fit shrink-0 h-full inline-block mr-[6px] py-[8px] lg:py-[10px] px-[10px] lg:px-[12px] rounded-[4px] bg-zinc-700 cursor-pointer text-sm lg:text-base leading-normal font-normal"
          onClick={() => clear()}>
          필터 초기화 하기
        </li>
      )}
      {isNotEmptyChips && (
        <>
          {chips.map((chip, index) => (
            <li
              key={`filter-chip-${index}`}
              className="w-fit shrink-0 h-full inline-block mx-[6px] py-[8px] lg:py-[10px] px-[10px] lg:px-[12px] gap-x-[4px] rounded-[4px] bg-zinc-800 cursor-pointer font-normal text-sm lg:text-base leading-normal"
              onClick={() => {
                if (close !== undefined) close(chip)
              }}>
              <div className="flex items-center justify-center">
                <div>{getLabelText(chip)}</div>
                <Image
                  className="ml-2"
                  width={20}
                  height={20}
                  src="/img/icon/cancel.svg"
                  alt="hcCancel"
                />
              </div>
            </li>
          ))}
          {collections &&
            collections.map((collection, index) => (
              <li
                key={`filter-chip-collection-${index}`}
                className="w-fit shrink-0 h-full inline-block mx-[6px] py-[8px] lg:py-[10px] px-[10px] lg:px-[12px] gap-x-[4px] rounded-[4px] bg-zinc-800 cursor-pointer font-normal text-sm lg:text-base leading-normal"
                onClick={() => {
                  if (removeCollectionFilter !== undefined) {
                    removeCollectionFilter(collection.address)
                  }
                }}>
                <div className="flex items-center justify-center">
                  <div>{getLabelText({ collectionChip: collection })}</div>
                  <Image
                    className="ml-2"
                    width={20}
                    height={20}
                    src="/img/icon/cancel.svg"
                    alt="hcCancel"
                  />
                </div>
              </li>
            ))}
          {propertyIds &&
            propertyIds.map((item, index) => (
              <li
                key={`filter-chip-property-${index}`}
                className="w-fit shrink-0 h-full inline-block mx-[6px] py-[8px] lg:py-[10px] px-[10px] lg:px-[12px] gap-x-[4px] rounded-[4px] bg-zinc-800 cursor-pointer font-normal text-sm lg:text-base leading-normal"
                onClick={() => {
                  if (removePropertyFilter !== undefined) {
                    removePropertyFilter(item.id)
                  }
                }}>
                <div className="flex items-center justify-center">
                  <div>{item.chipName}</div>
                  <Image
                    className="ml-2"
                    width={20}
                    height={20}
                    src="/img/icon/cancel.svg"
                    alt="hcCancel"
                  />
                </div>
              </li>
            ))}
          {activities !== undefined &&
            activities.length > 0 &&
            activities.map((activity, index) => (
              <li
                key={`filter-chip-activity-${index}`}
                className="w-fit shrink-0 h-full inline-block mx-[6px] py-[8px] lg:py-[10px] px-[10px] lg:px-[12px] gap-x-[4px] rounded-[4px] bg-zinc-800 cursor-pointer font-normal text-sm lg:text-base leading-normal"
                onClick={() => {
                  if (removeActivityFilter !== undefined) {
                    removeActivityFilter(activity)
                  }
                }}>
                <div className="flex items-center justify-center">
                  <div>{ACTIVITY_TYPE_NAME[activity]}</div>
                  <Image
                    className="ml-2"
                    width={20}
                    height={20}
                    src="/img/icon/cancel.svg"
                    alt="hcCancel"
                  />
                </div>
              </li>
            ))}
        </>
      )}
    </ul>
  )
}

FilteredChip.Layout = FilteredChipDesign

export default FilteredChip
