import Image from 'next/image'

const SigningCheckIcon = ({
  order,
  isCompleted,
  isLoading,
}: {
  order: number
  isCompleted: boolean
  isLoading: boolean
}) => {
  if (isCompleted)
    return <Image width={40} height={40} src="/img/icon/green-check.svg" alt="check" />
  else if (isLoading)
    return (
      <Image
        width={40}
        height={40}
        src="/img/common/loading.svg"
        className="animate-spin"
        alt="loading"
      />
    )
  return (
    <div className="bg-bgQuarternary flex-shrink-0 rounded-full w-10 h-10 text-textPrimary text-center font-medium text-lg p-1.5">
      {order}
    </div>
  )
}

export default SigningCheckIcon
