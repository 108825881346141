import { ISvgPreset } from '@/shared/types'

interface ISvgPresetWithOpacity extends ISvgPreset {
  opacity?: string
}

function HcCheckBold({ fill }: ISvgPresetWithOpacity) {
  return (
    <svg width={24} height={24} fill={fill}>
      <path
        d="M7.92 11.58a1.125 1.125 0 10-1.59 1.59l3 3c.439.44 1.151.44 1.59 0l6.75-6.75a1.125 1.125 0 00-1.59-1.59l-5.955 5.954L7.92 11.58z"
        fill={fill}
      />
    </svg>
  )
}

export default HcCheckBold
