import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { ROUTE_PATH } from '@/shared/constants'
import { useBreakpoint } from '@/shared/hooks'

import { Button } from '../core-ui'

const NoSearchResult = () => {
  const { isLG } = useBreakpoint()
  const { push } = useRouter()

  const handleRegisterCollection = useCallback(() => {
    if (window !== undefined) window.location.href = 'https://modernlion.typeform.com/collecion'
  }, [])

  const LinkToAllCollection = useCallback(() => {
    push(ROUTE_PATH.COLLECTIONS)
  }, [push])

  return (
    <div className="mt-[104px] flex justify-center">
      <div className="flex flex-col items-center justify-center gap-10">
        <div className="flex flex-col items-center justify-center gap-1 text-textSecondary lg:text-lg text-base font-medium md:w-full w-[320px]">
          <div>보여줄 NFT가 없습니다.</div>
          <div>전체 컬렉션 보기를 눌러 다른 아이템을 확인하세요.</div>
        </div>

        <div className="flex lg:gap-3 gap-2 lg:w-[346px] w-[264px]">
          <Button
            text="컬렉션 등록 요청하기"
            size={isLG ? 'lg' : 'sm'}
            theme={'Outlined'}
            onClick={handleRegisterCollection}
          />
          <Button
            text="전체 컬렉션 보기"
            size={isLG ? 'lg' : 'sm'}
            theme={'Accent'}
            onClick={LinkToAllCollection}
          />
        </div>
      </div>
    </div>
  )
}

export default NoSearchResult
