export const VerifiedBadge = ({ size = 'sm' }: { size?: 'base' | 'lg' | 'sm' }) => {
  if (size === 'lg') {
    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#FFD121" />
        <path
          d="M17.2265 15.0794H19.4808C19.5194 15.0794 19.558 15.0629 19.5856 15.0353L22.2289 12.3919C22.2565 12.3643 22.2731 12.3257 22.2731 12.2871V9.74861C22.2731 9.66583 22.2069 9.59961 22.1241 9.59961H19.5856C19.547 9.59961 19.5083 9.61617 19.4808 9.64376L16.8374 12.2871C16.8098 12.3147 16.7933 12.3533 16.7933 12.3919V14.9304C16.7933 15.0132 16.7271 15.0794 16.6443 15.0794H14.1196H13.8575C13.7747 15.0794 13.7085 15.0132 13.7085 14.9304V9.76792C13.7085 9.68515 13.6423 9.61892 13.5595 9.61892H12.3703H11.3714C11.3328 9.61892 11.2942 9.63548 11.2666 9.66307L9.61932 11.3103V11.3103C9.60695 11.3227 9.60001 11.3395 9.60001 11.357V23.2136C9.60001 23.2964 9.66623 23.3626 9.749 23.3626H12.3316C12.3703 23.3626 12.4089 23.346 12.4365 23.3184L13.6616 22.0933C13.6892 22.0657 13.7057 22.0271 13.7057 21.9885V17.9738C13.7057 17.8911 13.7719 17.8248 13.8547 17.8248H14.0561C14.0948 17.8248 14.1334 17.8414 14.161 17.869L19.6077 23.3157C19.6353 23.3433 19.6739 23.3598 19.7125 23.3598H22.251C22.3338 23.3598 22.4 23.2936 22.4 23.2108V20.6723C22.4 20.6337 22.3835 20.5951 22.3559 20.5675L17.1216 15.3305C17.0306 15.2367 17.0968 15.0794 17.2265 15.0794Z"
          fill="black"
        />
      </svg>
    )
  }

  if (size === 'base') {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#FFD121" />
        <path
          d="M12.92 11.3098H14.6108C14.6397 11.3098 14.6687 11.2974 14.6894 11.2767L16.6719 9.2942C16.6926 9.2735 16.705 9.24453 16.705 9.21556V7.3117C16.705 7.24962 16.6553 7.19995 16.5933 7.19995H14.6894C14.6604 7.19995 14.6315 7.21237 14.6108 7.23306L12.6283 9.21556C12.6076 9.23625 12.5952 9.26522 12.5952 9.2942V11.1981C12.5952 11.2601 12.5455 11.3098 12.4834 11.3098H10.5899H10.3933C10.3312 11.3098 10.2815 11.2601 10.2815 11.1981V7.32619C10.2815 7.2641 10.2319 7.21444 10.1698 7.21444H9.27788H8.52876C8.49979 7.21444 8.47081 7.22685 8.45012 7.24755L7.21468 8.48299C7.20541 8.49226 7.2002 8.50484 7.2002 8.51796V17.4104C7.2002 17.4725 7.24986 17.5222 7.31194 17.5222H9.24891C9.27788 17.5222 9.30686 17.5098 9.32755 17.4891L10.2464 16.5702C10.2671 16.5496 10.2795 16.5206 10.2795 16.4916V13.4806C10.2795 13.4185 10.3291 13.3689 10.3912 13.3689H10.5423C10.5713 13.3689 10.6002 13.3813 10.6209 13.402L14.706 17.487C14.7266 17.5077 14.7556 17.5201 14.7846 17.5201H16.6884C16.7505 17.5201 16.8002 17.4704 16.8002 17.4084V15.5045C16.8002 15.4755 16.7878 15.4466 16.7671 15.4259L12.8414 11.4981C12.7731 11.4278 12.8228 11.3098 12.92 11.3098Z"
          fill="black"
        />
      </svg>
    )
  }

  if (size === 'sm') {
    return (
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect x="0.669922" width="16" height="16" rx="8" fill="#FFCC33" />
        <path
          d="M9.44209 7.10922H10.6162C10.6363 7.10922 10.6564 7.10059 10.6708 7.08622L12.0475 5.70954C12.0619 5.69517 12.0705 5.67505 12.0705 5.65493V4.33285C12.0705 4.28974 12.036 4.25525 11.9929 4.25525H10.6708C10.6507 4.25525 10.6306 4.26387 10.6162 4.27824L9.23946 5.65493C9.22509 5.6693 9.21647 5.68942 9.21647 5.70954V7.03162C9.21647 7.07473 9.18198 7.10922 9.13886 7.10922H7.82393H7.6874C7.64429 7.10922 7.6098 7.07473 7.6098 7.03162V4.34291C7.6098 4.2998 7.57531 4.26531 7.5322 4.26531H6.91281H6.39258C6.37246 4.26531 6.35234 4.27393 6.33797 4.2883L5.48003 5.14622C5.47359 5.15266 5.46997 5.16139 5.46997 5.1705V11.3456C5.46997 11.3887 5.50446 11.4232 5.54757 11.4232H6.89269C6.91281 11.4232 6.93293 11.4146 6.9473 11.4002L7.58537 10.7622C7.59974 10.7478 7.60836 10.7277 7.60836 10.7076V8.61667C7.60836 8.57356 7.64285 8.53907 7.68597 8.53907H7.79087C7.81099 8.53907 7.83111 8.5477 7.84548 8.56207L10.6823 11.3988C10.6967 11.4132 10.7168 11.4218 10.7369 11.4218H12.059C12.1021 11.4218 12.1366 11.3873 12.1366 11.3442V10.0221C12.1366 10.002 12.128 9.98186 12.1136 9.96749L9.38748 7.23999C9.34006 7.19113 9.37455 7.10922 9.44209 7.10922Z"
          fill="#141414"
        />
      </svg>
    )
  }

  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Originals Badge" clipPath="url(#clip0_2135_13872)">
        <g id="Vector">
          <path
            d="M12.3301 6.5C12.3301 9.81371 9.64379 12.5 6.33008 12.5C3.01637 12.5 0.330078 9.81371 0.330078 6.5C0.330078 3.18629 3.01637 0.5 6.33008 0.5C9.64379 0.5 12.3301 3.18629 12.3301 6.5Z"
            fill="#FFCC33"
          />
          <path
            d="M6.80917 5.95249H7.68975C7.70484 5.95249 7.71993 5.94602 7.7307 5.93524L8.76325 4.90273C8.77403 4.89195 8.7805 4.87686 8.7805 4.86177V3.87021C8.7805 3.83788 8.75463 3.81201 8.7223 3.81201H7.7307C7.71561 3.81201 7.70052 3.81848 7.68975 3.82926L6.6572 4.86177C6.64642 4.87255 6.63995 4.88764 6.63995 4.90273V5.89429C6.63995 5.92662 6.61408 5.95249 6.58175 5.95249H5.49315C5.46082 5.95249 5.43495 5.92662 5.43495 5.89429V3.87776C5.43495 3.84542 5.40908 3.81956 5.37675 3.81956H4.52204C4.50695 3.81956 4.49186 3.82602 4.48108 3.8368L3.83762 4.48024C3.83279 4.48507 3.83008 4.49162 3.83008 4.49845V9.12979C3.83008 9.16212 3.85595 9.18799 3.88828 9.18799H4.89712C4.91221 9.18799 4.9273 9.18152 4.93808 9.17074L5.41663 8.69221C5.42741 8.68143 5.43387 8.66634 5.43387 8.65125V7.08308C5.43387 7.05075 5.45974 7.02488 5.49207 7.02488H5.57076C5.58584 7.02488 5.60093 7.03135 5.61171 7.04212L7.73933 9.16967C7.7501 9.18044 7.76519 9.18691 7.78028 9.18691H8.77188C8.80421 9.18691 8.83008 9.16104 8.83008 9.12871V8.13715C8.83008 8.12206 8.82361 8.10697 8.81283 8.0962L6.76821 6.05057C6.73264 6.01392 6.75851 5.95249 6.80917 5.95249Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2135_13872">
          <rect width="12" height="12" fill="white" transform="translate(0.330078 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
