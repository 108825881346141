import Image from 'next/image'

import { useBreakpoint } from '@/shared/hooks'

interface ISnsButtonProps {
  link: string
  title: string
  subtitle?: string
}

export const DiscordButton = ({ link, title, subtitle }: ISnsButtonProps) => {
  const { isLG } = useBreakpoint()
  return (
    <a
      href={`https://discord.gg/${link}`}
      target="_blank"
      rel="noreferrer"
      className="bg-[#434DD2] text-white w-[163px] lg:w-60 h-14 lg:h-[72px] rounded-xl flex gap-2 justify-center items-center">
      <Image
        width={isLG ? 10 : 5}
        height={isLG ? 10 : 5}
        className="aspect-square"
        src="/img/icon/discord.svg"
        alt="discord icon"
      />
      <span className="text-sm lg:text-xl !leading-none font-medium text-center">
        {title}
        {subtitle && (
          <>
            <br />
            <span className="text-xs lg:text-sm leading-normal font-normal text-center">
              {subtitle}
            </span>
          </>
        )}
      </span>
    </a>
  )
}

export const TwitterButton = ({ link, title, subtitle }: ISnsButtonProps) => {
  const { isLG } = useBreakpoint()
  return (
    <a
      href={`https://twitter.com/${link}`}
      target="_blank"
      className="bg-[#1D9BF0] text-white w-[163px] lg:w-60 h-14 lg:h-[72px] rounded-xl flex gap-1 lg:gap-2 justify-center items-center"
      rel="noreferrer">
      <Image
        width={isLG ? 10 : 5}
        height={isLG ? 10 : 5}
        className="aspect-square"
        src="/img/icon/twitter.svg"
        alt="twitter icon"
      />
      <span className="text-sm lg:text-xl !leading-none font-medium text-center">
        {title}
        {subtitle && (
          <>
            <br />
            <span className="text-xs lg:text-sm leading-normal font-normal text-center">
              {subtitle}
            </span>
          </>
        )}
      </span>
    </a>
  )
}

export const KakaoButton = ({ link, title, subtitle }: ISnsButtonProps) => {
  const { isLG } = useBreakpoint()
  return (
    <a
      href={`https://open.kakao.com/o/${link}`}
      target="_blank"
      rel="noreferrer"
      className="bg-[#FBE54D] text-black w-[163px] lg:w-60 h-14 lg:h-[72px] rounded-xl flex gap-2 justify-center items-center">
      <Image
        width={isLG ? 10 : 5}
        height={isLG ? 10 : 5}
        className="aspect-square"
        src="/img/icon/kakao.png"
        alt="kakao icon"
      />
      <span className="text-sm lg:text-xl !leading-none font-medium text-center">
        {title}
        {subtitle && (
          <>
            <br />
            <span className="text-xs lg:text-sm leading-normal font-normal text-center">
              {subtitle}
            </span>
          </>
        )}
      </span>
    </a>
  )
}
