import { range } from 'lodash'
import Link from 'next/link'
import { ReactNode } from 'react'

import { TAB_BAR_THEME } from '@/shared/constants'
import { cx } from '@/shared/utils'

const styleClassName = {
  wrapper: 'mb-6 px-4 h-[calc(100% + 1px)] border-b border-b-appBorderQuarternary',
  ul: 'w-full border-solid whitespace-nowrap flex justify-start gap-2 overflow-x-scroll scrollbar-hide',
  detailUl: 'w-full whitespace-nowrap flex gap-4 overflow-x-scroll scrollbar-hide px-4',
  li: 'text-xs pt-4 pb-3 px-1 font-semibold text-appTextTertiary shrink-0',
  detailLi:
    'text-xs px-[12px] py-[8px] bg-appBgTertiary rounded text-appTextAccent font-medium flex-grow-0 flex-shrink-0',
  selectedLi: '!text-appTextInversePrimary !bg-appBgAccent',
  selected: 'border-b-4 border-borderAccent !text-textAccent',
}

type LinkType = { to: string; detail?: LinkType[]; name: string }

export interface ITabData<TabDataKey> {
  key: TabDataKey
  name: string
  handler?: (key: string) => void
}

interface ITabBarProps<TabDataKey> {
  theme?: keyof typeof TAB_BAR_THEME
  links?: LinkType[]
  pathname?: string
  wrapperClassNames?: string
  children?: ReactNode
  tabData?: ITabData<TabDataKey>[] | null
  selectedTab?: TabDataKey
}

interface ILinksTabBarProp {
  links: LinkType[] | undefined
  pathname: string | undefined
  children: ReactNode
  wrapperClassNames?: string
}

interface IHandlerTabBarProp<TabDataKey> {
  tabData: ITabData<TabDataKey>[] | null | undefined
  children: ReactNode
  selectedTab?: TabDataKey
  wrapperClassNames?: string
}

const findDetailLinks = (links: LinkType[], pathname: string) => {
  const link = links.find(
    link =>
      pathname === link.to ||
      (link.detail && link.detail.some(detailLink => pathname === detailLink.to)),
  )

  return link ? link.detail : null
}

const getIsSelectedTab = ({ to, detail }: { to: string; detail?: LinkType[] }, pathname: string) =>
  pathname === to || (detail?.some(detailLink => pathname === detailLink.to) ?? false)

const TabBarSkeleton = ({ type }: { type?: string }) => {
  if (type === 'filter') {
    return (
      <div className="w-full flex items-center gap-4 px-4">
        {range(5).map(index => (
          <div
            key={`tab-bar-filter-skeleton-${index}`}
            className="w-[64px] h-[34px] rounded-md bg-appBgQuaternary"
          />
        ))}
      </div>
    )
  }

  return (
    <div className="w-full flex items-center gap-2 px-4 pb-[23px] border-b-[1px] border-appBorderQuarternary">
      {range(4).map(index => (
        <div
          key={`tab-bar-skeleton-${index}`}
          className="w-[50px] h-[18px] rounded-[2px] bg-appBgQuaternary"
        />
      ))}
    </div>
  )
}

const LinksTabBar = ({ links, pathname, children, wrapperClassNames }: ILinksTabBarProp) => {
  if (links === undefined || pathname === undefined) {
    return <></>
  }

  const detailLinks = findDetailLinks(links, pathname)

  return (
    <>
      <div className={cx(wrapperClassNames, styleClassName.wrapper)}>
        <ul className={styleClassName.ul}>
          {links?.map(({ name, to, detail }, idx) => (
            <li
              key={idx}
              className={cx(
                styleClassName.li,
                getIsSelectedTab({ to, detail }, pathname) ? styleClassName.selected : '',
              )}>
              <Link href={to} className="no-underline">
                {name}
              </Link>
            </li>
          ))}
        </ul>
        {children}
      </div>

      {detailLinks && (
        <ul className={styleClassName.detailUl}>
          {detailLinks.map(({ name, to }, idx) => (
            <li
              key={idx}
              className={cx(
                styleClassName.detailLi,
                pathname === to ? styleClassName.selectedLi : '',
              )}>
              <Link href={to} className="no-underline">
                {name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

const HandlerTabBar = <TabDataKey extends string>({
  tabData,
  children,
  selectedTab,
  wrapperClassNames,
}: IHandlerTabBarProp<TabDataKey>) => {
  if (tabData === undefined || tabData === null || selectedTab === undefined) {
    return <></>
  }

  return (
    <div className={cx(wrapperClassNames, styleClassName.wrapper)}>
      <ul className={styleClassName.ul}>
        {tabData.map(({ name, key, handler }, idx) => (
          <li
            key={`tabData-${idx}`}
            onClick={() => {
              if (handler) {
                handler(key)
              }
              selectedTab = key
            }}
            className={cx(styleClassName.li, selectedTab === key ? styleClassName.selected : '')}>
            {name}
          </li>
        ))}
      </ul>
      {children}
    </div>
  )
}

const TabBar = <TabDataKey extends string>({
  links,
  children,
  tabData,
  pathname,
  selectedTab,
  wrapperClassNames,
  theme = `${TAB_BAR_THEME.links}`,
}: ITabBarProps<TabDataKey>) => {
  const handlerTabBarProps = {
    tabData,
    children,
    selectedTab,
    wrapperClassNames,
  }

  const linkTabBarProps = {
    links,
    children,
    pathname,
    wrapperClassNames,
  }

  const mappingTabBars: { [key: string]: JSX.Element } = {
    [TAB_BAR_THEME.links]: <LinksTabBar {...linkTabBarProps} />,
    [TAB_BAR_THEME.handler]: <HandlerTabBar {...handlerTabBarProps} />,
  }

  return mappingTabBars[theme] ?? <></>
}

export default TabBar
TabBar.Skeleton = TabBarSkeleton
