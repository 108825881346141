import { ISvgPreset } from '@/shared/types'

interface ISvgPresetWithOpacity extends ISvgPreset {
  opacity?: string
}

function HcChevronRight({ width, height, fill, opacity = '1' }: ISvgPresetWithOpacity) {
  const fillColor = fill?.startsWith('color-') ? `var(--${fill})` : fill

  return (
    <svg
      width={width ? width : '1em'}
      height={height ? height : '1em'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3485 5.15147C9.8799 4.68284 9.1201 4.68284 8.65147 5.15147C8.18284 5.6201 8.18284 6.3799 8.65147 6.84853L10.3485 5.15147ZM15.5 12L16.3485 12.8485C16.8172 12.3799 16.8172 11.6201 16.3485 11.1515L15.5 12ZM8.65147 17.1515C8.18284 17.6201 8.18284 18.3799 8.65147 18.8485C9.1201 19.3172 9.8799 19.3172 10.3485 18.8485L8.65147 17.1515ZM8.65147 6.84853L14.6515 12.8485L16.3485 11.1515L10.3485 5.15147L8.65147 6.84853ZM14.6515 11.1515L8.65147 17.1515L10.3485 18.8485L16.3485 12.8485L14.6515 11.1515Z"
        fill={fillColor}
        opacity={opacity}
      />
    </svg>
  )
}

export default HcChevronRight
