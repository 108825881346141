import { useTranslation } from 'react-i18next'

import { cx } from '@/shared/utils'

const BenefitStatusLabel = ({ status }: { status: boolean }) => {
  const { t } = useTranslation('common')
  const benefitStatusClassMapping = {
    AVAILABLE: {
      title: `${t('common.item-benefit-status-available-web')}`,
      bgClassName: 'bg-bgAccent',
      titleClassName: 'text-textAccent',
    },
    UNAVAILABLE: {
      title: `${t('common.claim-status-finished')}`,
      bgClassName: 'bg-iconSecondary',
      titleClassName: 'text-textSecondary',
    },
  }

  const getBenefitStatus = ({ status }: { status: boolean }) => {
    if (status) {
      return benefitStatusClassMapping.AVAILABLE
    }

    return benefitStatusClassMapping.UNAVAILABLE
  }

  const { title, bgClassName, titleClassName } = getBenefitStatus({ status })

  return (
    <div className="flex items-center gap-x-1 shrink-0">
      <div className={cx(bgClassName, 'rounded-full w-1 h-1')} />
      <p className={cx(titleClassName, 'text-xs font-medium')}>{title}</p>
    </div>
  )
}

export default BenefitStatusLabel
