import { ISvgPreset } from '@/shared/types'

function HcX({ width, height, fill }: ISvgPreset) {
  return (
    <svg
      width={width ? width : '1em'}
      height={height ? height : '1em'}
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M6 18L18 6M6 6l12 12"
        stroke={fill ? fill : '#fff'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HcX
