import { Listbox } from '@headlessui/react'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import RefreshButton from '@/shared/assets/RefreshButton'
import { ISortOption } from '@/shared/types'

interface IItemListHeaderProps {
  refetch?: () => void
  totalCount?: number | null
  totalCountLabel?: string
  sortOptions: ISortOption[]
  setOrderAndOrderBy?: ({ order, orderBy }: { order: string; orderBy: string }) => void
}

export const ItemListHeader = ({
  refetch,
  totalCount,
  sortOptions,
  totalCountLabel,
  setOrderAndOrderBy,
}: IItemListHeaderProps) => {
  const { t } = useTranslation(['common'])
  const sortOptionsValue = sortOptions[0] ?? [
    {
      text: t('common:common.label-list-collect-recent'),
      order: 'ownerUpdatedAp',
      orderBy: 'DESC',
    },
  ]
  const [selectedOption, setSelectedOption] = useState(sortOptionsValue)

  useEffect(() => {
    setOrderAndOrderBy &&
      setOrderAndOrderBy({
        order: selectedOption.order,
        orderBy: selectedOption.orderBy,
      })
  }, [selectedOption.order, selectedOption.orderBy, setOrderAndOrderBy])

  if (totalCount === 0) return <></>

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-2">
          {refetch && (
            <RefreshButton
              size="sm"
              theme="white"
              onClick={() => {
                refetch()
                toast(`${t('common:common.update-requested')}`, {
                  toastId: 'refresh',
                })
              }}
            />
          )}

          <p className="text-sm">
            {totalCountLabel?.replace('{value}', totalCount?.toString() ?? '0')}
          </p>
        </div>

        {setOrderAndOrderBy && sortOptions && (
          <Listbox value={selectedOption} onChange={setSelectedOption}>
            <div className="relative flex items-center">
              <Listbox.Button className="flex items-center">
                <p className="text-sm leading-[18px] font-medium text-white">
                  {selectedOption?.text}
                </p>

                {sortOptions.length > 1 && (
                  <Image
                    width={20}
                    height={20}
                    loading="lazy"
                    alt="filter_button"
                    src="/img/icon/arrowb-down.svg"
                  />
                )}
              </Listbox.Button>

              {sortOptions.length > 1 && (
                <Listbox.Options className="w-[160px] absolute top-7 right-0 bg-appBgTertiary rounded-sm">
                  {sortOptions.map((option, index) => {
                    return (
                      <Listbox.Option
                        className="px-4 py-[10px] text-sm font-medium text-appTextPrimary leading-[18px]"
                        value={option}
                        key={index}>
                        {option.text}
                      </Listbox.Option>
                    )
                  })}
                </Listbox.Options>
              )}
            </div>
          </Listbox>
        )}
      </div>
    </div>
  )
}
