import { Popover, Transition } from '@headlessui/react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Fragment, useCallback } from 'react'

import { VerifiedBadge } from '@/shared/assets'
import { COLLECTION_CARD_OPTIONS, ROUTE_PATH } from '@/shared/constants'
import { CollectionCardOption, ICollection, IWhiteListCollection } from '@/shared/types'
import { cx } from '@/shared/utils'

export interface ICollectionCardProps {
  collection: ICollection | IWhiteListCollection
  isOwner?: boolean
  isRoyaltyReceivers?: boolean
  isMoreOption?: boolean
  className?: string
}

const CollectionCard = ({
  collection,
  isOwner,
  isRoyaltyReceivers,
  isMoreOption,
  className,
}: ICollectionCardProps) => {
  const router = useRouter()

  const moreButtonOptions: CollectionCardOption[] = isRoyaltyReceivers
    ? ['PAYOUT']
    : isOwner
    ? ['COLLECTION_EDIT', 'PAYOUT']
    : ['PAYOUT']

  const handleClickCollectionEditButton = useCallback(() => {
    router.push(
      ROUTE_PATH.COLLECTION_EDIT.replace(':collectionAddress', collection.collectionAddress),
    )
  }, [collection.collectionAddress, router])

  const handleClickPayoutButton = useCallback(() => {
    router.push(
      ROUTE_PATH.COLLECTION_PAYOUT.replace(':collectionAddress', collection.collectionAddress),
    )
  }, [collection.collectionAddress, router])

  const handleClickActionMappings: Record<CollectionCardOption, () => void> = {
    COLLECTION_EDIT: handleClickCollectionEditButton,
    PAYOUT: handleClickPayoutButton,
  }

  return (
    <div className={cx(className, 'rounded-[16px] bg-bgTertiary overflow-hidden relative')}>
      <Link
        href={ROUTE_PATH.COLLECTION_DETAIL.replace(
          ':collectionAddress',
          collection.collectionAddress,
        )}>
        <div className="p-[32px] relative">
          {isMoreOption && moreButtonOptions.length !== 0 && (
            <div className="absolute right-[12px] top-[12px] z-20">
              <Popover className="relative flex items-center">
                {({ close, open }) => (
                  <>
                    <Popover.Button value={open ? 'true' : 'false'}>
                      <div className="w-[32px] h-[32px] bg-[#3F3F46] rounded-[8px] flex items-center justify-center">
                        <Image width={24} height={24} src="/img/icon/more.svg" alt="more" />
                      </div>
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1">
                      <Popover.Panel
                        className="absolute right-0 top-[36px] px-2 w-[160px] sm:px-0"
                        onClick={(event: { preventDefault: () => void }) => {
                          event.preventDefault()
                          close()
                        }}>
                        <div className="rounded-lg bg-bgTertiary shadow-lg ring-1 ring-black border border-bgQuarternary ring-opacity-5 overflow-hidden">
                          <div className="bg-bgTertiary relative grid">
                            {moreButtonOptions.map((option, index) => (
                              <button
                                key={`item-card-option-${index}`}
                                type="button"
                                onClick={handleClickActionMappings[option]}
                                className="text-white py-[8px] px-[16px] text-sm leading-normal font-normal text-left">
                                {COLLECTION_CARD_OPTIONS[option]}
                              </button>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
          )}
          <div className="h-[100px] bg-bgQuarternary absolute top-0 left-0 w-full">
            {collection.backgroundImgUrl !== '' && (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                className="w-full h-full object-cover"
                src={collection.backgroundImgUrl}
                onError={e => {
                  e.currentTarget.style.display = 'none'
                }}
                alt="collection_image"
              />
            )}
          </div>
          <div className="mt-[28px] flex justify-center">
            <div className="flex justify-center items-center h-[85px] w-[85px] overflow-hidden rounded-full bg-bgTertiary z-10 border-[5px] border-solid border-zinc-800">
              <div className="w-[80px] h-[80px] bg-[#505057] rounded-full">
                {collection.imgUrl !== '' && (
                  // eslint-disable-next-line @next/next/no-img-element
                  <img
                    className="w-20 h-20 object-cover"
                    src={collection.imgUrl}
                    onError={e => {
                      e.currentTarget.style.display = 'none'
                    }}
                    alt="collection_image"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="mt-[12px] text-[18px] font-[800] leading-[27px] text-center truncate flex gap-1 items-center justify-center text-white">
            <span className="truncate">{collection.title}</span>
            {collection.isOriginals && <VerifiedBadge />}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default CollectionCard

CollectionCard.Skeleton = function CollectionCardSkeleton({ classNames }: { classNames?: string }) {
  return (
    <div
      className={cx(
        'animate-pulse p-[32px] rounded-[16px] bg-bgTertiary overflow-hidden relative w-full',
        classNames,
      )}>
      <div className="h-[100px] bg-bgQuarternary absolute top-0 left-0 w-full" />
      <div className="mt-[28px] flex justify-center">
        <div className="flex justify-center items-center h-[85px] w-[85px] overflow-hidden rounded-full bg-bgTertiary z-10 border-[5px] border-solid border-zinc-800">
          <div className="w-[80px] h-[80px] rounded-full bg-[#505057] z-10"></div>
        </div>
      </div>
      <div className="mt-[12px] mx-auto text-[18px] max-w-[150px] h-[23px] font-[800] leading-[22.82px] text-center w-full bg-bgQuarternary rounded-lg"></div>
    </div>
  )
}
