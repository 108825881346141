import { useBreakpoint } from '@/shared/hooks'
import { zeroPad } from '@/shared/utils'

interface ICountdownProps {
  days: number
  hours: number
  minutes: number
  seconds: number
}

const Countdown = ({ days, hours, minutes, seconds }: ICountdownProps) => {
  const { is2XL } = useBreakpoint()
  return (
    <>
      <div
        className={
          is2XL
            ? 'text-[120px] font-bold font-Pretendard leading-tight text-center '
            : 'text-4xl md:text-[80px] font-bold font-Pretendard leading-tight text-center '
        }>
        {zeroPad(days, 2)}
      </div>
      <span
        className={
          is2XL
            ? 'font-semibold text-3xl leading-tight align-bottom md:mb-6'
            : 'font-semibold text-sm md:text-2xl leading-tight align-bottom mb-[3px] md:mb-[15px]'
        }>
        일
      </span>
      <div
        className={
          is2XL
            ? 'text-[120px] font-bold font-Pretendard leading-tight text-center ml-1 md:ml-4 '
            : 'text-4xl md:text-[80px] font-bold font-Pretendard leading-tight text-center ml-1 md:ml-4 '
        }>
        {zeroPad(hours, 2)}
      </div>
      <div
        className={
          is2XL
            ? 'font-semibold text-3xl leading-tight align-bottom md:mb-6'
            : 'font-semibold text-sm md:text-2xl leading-tight align-bottom mb-[3px] md:mb-[15px]'
        }>
        시간
      </div>
      <div
        className={
          is2XL
            ? 'text-[120px] font-bold font-Pretendard leading-tight text-center ml-1 md:ml-4 '
            : 'text-4xl md:text-[80px] font-bold font-Pretendard leading-tight text-center ml-1 md:ml-4 '
        }>
        {zeroPad(minutes, 2)}
      </div>
      <div
        className={
          is2XL
            ? 'font-semibold text-3xl leading-tight align-bottom md:mb-6'
            : 'font-semibold text-sm md:text-2xl leading-tight align-bottom mb-[3px] md:mb-[15px]'
        }>
        분
      </div>
      <div
        className={
          is2XL
            ? 'text-[120px] font-bold font-Pretendard leading-tight text-center ml-1 md:ml-4 '
            : 'text-4xl md:text-[80px] font-bold font-Pretendard leading-tight text-center ml-1 md:ml-4 '
        }>
        {zeroPad(seconds, 2)}
      </div>
      <div
        className={
          is2XL
            ? 'font-semibold text-3xl leading-tight align-bottom md:mb-6'
            : 'font-semibold text-sm md:text-2xl leading-tight align-bottom mb-[3px] md:mb-[15px]'
        }>
        초
      </div>
    </>
  )
}

export default Countdown
