import Image from 'next/image'

import { IConSizeType, ITradeTypeIconReturnSpecificSize, PaymentType } from '@/shared/types'
import { cx, getTradeTypeIcon, numberFormat } from '@/shared/utils'

const PRICE_STYLE_MAPPINGS = {
  xs: 'text-xs leading-[15px] font-semibold',
  sm: 'text-sm leading-[18px] font-semibold',
  base: 'text-base leading-[20px] font-semibold',
  lg: 'text-lg leading-[27px] font-semibold',
  xl: 'text-xl leading-[28px] font-semibold',
  '2xl': 'text-2xl leading-[33px]',
}

interface IPriceLabelProps {
  price?: number | null
  size: IConSizeType
  paymentType: PaymentType | null
  priceTextStyle?: string
  isShowPaymentType?: boolean
}

export const PriceLabel = ({
  price,
  size,
  paymentType,
  priceTextStyle,
  isShowPaymentType = false,
}: IPriceLabelProps) => {
  if (!paymentType) return <></>

  const { url, width, height } = getTradeTypeIcon(
    paymentType,
    size,
  ) as ITradeTypeIconReturnSpecificSize

  return (
    <div className="flex items-center gap-1">
      <Image width={width} height={height} src={url} alt="payment type logo" />
      <p className={cx(PRICE_STYLE_MAPPINGS[size], priceTextStyle)}>
        {price ? numberFormat(price) : '-'}
        {isShowPaymentType && ` ${paymentType}`}
      </p>
    </div>
  )
}
