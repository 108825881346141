const NotConnectInternet = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <img alt="not_connect_internet" src="img/common/network-error.svg" />
      <p className="mt-4 text-textSecondary">인터넷을 연결해주세요.</p>
    </div>
  )
}

export default NotConnectInternet
