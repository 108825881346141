import { XMarkIcon } from '@heroicons/react/24/solid'
import dynamic from 'next/dynamic'
import { PropsWithChildren } from 'react'

import { Button } from '../core-ui'

const DynamicComponentWithNoSSR = dynamic(() => import('../core-ui/BottomSheet'), { ssr: false })

type FILTER_BUTTON_OPTION = 'SUBMIT' | 'CLEAR'

interface IFilterBottomSheet extends PropsWithChildren {
  isInputPriceError?: boolean
  closeSheet: () => void
  bottomButtonHandlerMappings?: Record<FILTER_BUTTON_OPTION, () => void>
}

export default function FilterBottomSheet({
  children,
  closeSheet,
  isInputPriceError,
  bottomButtonHandlerMappings,
}: IFilterBottomSheet) {
  return (
    <DynamicComponentWithNoSSR closeSheet={closeSheet}>
      <div className="relative h-full">
        <XMarkIcon
          className="w-8 h-8 absolute my-[20px] mx-[16px] right-0 cursor-pointer"
          onClick={closeSheet}
        />

        <div className="h-[64px] mb-6 flex justify-center items-center text-center">
          <div className="text-lg leading-normal font-medium">필터</div>
        </div>

        <div className="h-[calc(100%-64px)] pb-[calc(80%-64px)] px-[16px] overflow-y-auto scrollbar-hide">
          <div className={bottomButtonHandlerMappings ? 'pb-[41px] min-h-full' : 'min-h-full'}>
            {children}
          </div>

          {bottomButtonHandlerMappings && (
            <div className="fixed bg-bgSecondary p-[16px] bottom-0 left-0 w-full flex gap-x-[8px] items-end z-[50]">
              <Button
                text="필터 초기화 하기"
                size="sm"
                theme="Outlined"
                className="flex-1"
                onClick={bottomButtonHandlerMappings.CLEAR}
              />
              <Button
                text="적용하기"
                size="sm"
                theme="Accent"
                color="text-black"
                disable={isInputPriceError}
                className="flex-1"
                onClick={bottomButtonHandlerMappings.SUBMIT}
              />
            </div>
          )}
        </div>
      </div>
    </DynamicComponentWithNoSSR>
  )
}
