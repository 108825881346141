import { memo } from 'react'

import { ISvgPreset } from '@/shared/types'

function HcCancel({ width = '20', height = '20', fill = '#fff' }: ISvgPreset) {
  return (
    <svg width={width} height={height} fill="none">
      <path
        d="M14.994 5.006a.875.875 0 00-1.238 0L10 8.763 6.244 5.006l-.067-.06a.875.875 0 00-1.17 1.298L8.762 10l-3.757 3.756-.06.067a.875.875 0 001.298 1.17L10 11.238l3.756 3.757.067.06a.875.875 0 001.17-1.298L11.238 10l3.757-3.756.06-.067a.875.875 0 00-.06-1.17z"
        fill={fill}
        fillOpacity={0.64}
      />
    </svg>
  )
}

const MemoHcCancel = memo(HcCancel)
export default MemoHcCancel
